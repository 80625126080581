<template>
	<div>
		<Header title="战斗配置"></Header>
		<Main>
			<div class="page">
				<button class="add" @click="addConfig" type="button">添加配置</button>
				<ul>
					<FightList v-for="(i, j) in shuju" :key="j" :name="i.name">
						<button @click="showFight(j)">查看</button>
					</FightList>
				</ul>
			</div>
			<FightInfo :name="name" v-if="showfight">
				<img slot="img" @click="closeFight" src="../assets/img/cha.png" alt="">
				<FightContent :config="config" v-if="info" slot="info"></FightContent>
				<AddFight v-else slot="info"></AddFight>
			</FightInfo>
		</Main>
	</div>
</template>

<script>
	import Header from "@/components/common/Header";
	import Main from "@/components/common/Main";
	import FightList from "@/components/common/FightList";
	import FightInfo from "@/components/common/FightInfo";
	import FightContent from "@/components/common/FightContent";
	import AddFight from "@/components/common/AddFight";
	import {getFightInfo} from "@/network/api";
	export default {
		name: "User",
		created() {
			if (this.$store.state.token) {
				getFightInfo(this.$store.state.token).then(
					res => {
						this.shuju = res.msg
					}
				)
			}
		},
		data() {
			return {
				showfight: false,
				name: "",
				config: [],
				info: true,
				shuju: ""
			}
		},
		components: {
			Header,
			Main,
			FightList,
			FightInfo,
			FightContent,
			AddFight
		},
		beforeCreate() {
			if (!(this.$store.state.token)) {
				this.$router.replace("/user/login")
			}
		},
		methods: {
			addConfig() {
				this.info = false
				this.name = "添加配置"
				this.showfight = true
			},
			showFight(i) {
				this.info = true
				this.name = this.shuju[i].name
				this.config = this.shuju[i].config
				this.showfight = true
			},
			closeFight() {
				this.showfight = false
			}
		}
	}
</script>

<style scoped>
	.add {
		height: 2rem;
		width: 100%;
		background-color: #666;
		border-radius: 1rem;
		color: #fff;
		font-weight: 700;
		font-size: 1rem;
	}
	.page {
		margin: 1rem 1rem 0;
	}
	ul {
		margin-top: 1rem;
		padding: 0 1rem;
	}
</style>
