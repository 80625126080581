<template>
	<div class="main">
		<div>
			<div>主将：</div>
			<ul>
				<li>技能一：{{ skill[config[0].skill[0].s] }}&nbsp;&nbsp;{{ config[0].skill[0].count }}次</li>
				<li>技能二：{{ skill[config[0].skill[1].s] }}&nbsp;&nbsp;{{ config[0].skill[1].count }}次</li>
				<li>技能三：{{ skill[config[0].skill[2].s] }}&nbsp;&nbsp;{{ config[0].skill[2].count }}次</li>
				<li>技能四：{{ skill[config[0].skill[3].s] }}&nbsp;&nbsp;{{ config[0].skill[3].count }}次</li>
				<li>血量&lt;{{ config[0].hp }}使用{{ yao[0][config[0].hpYao] }}</li>
				<li>蓝量&lt;{{ config[0].mp }}使用{{ yao[1][config[0].mpYao] }}</li>
			</ul>
		</div>
		<div>
			<div>副一：</div>
			<ul>
				<li>技能一：{{ skill[config[1].skill[0].s] }}&nbsp;&nbsp;{{ config[1].skill[0].count }}次</li>
				<li>技能二：{{ skill[config[1].skill[1].s] }}&nbsp;&nbsp;{{ config[1].skill[1].count }}次</li>
				<li>技能三：{{ skill[config[1].skill[2].s] }}&nbsp;&nbsp;{{ config[1].skill[2].count }}次</li>
				<li>技能四：{{ skill[config[1].skill[3].s] }}&nbsp;&nbsp;{{ config[1].skill[3].count }}次</li>
				<li>血量&lt;{{ config[1].hp }}使用{{ yao[0][config[1].hpYao] }}</li>
				<li>蓝量&lt;{{ config[1].mp }}使用{{ yao[1][config[1].mpYao] }}</li>
			</ul>
		</div>
		<div>
			<div>副二：</div>
			<ul>
				<li>技能一：{{ skill[config[2].skill[0].s] }}&nbsp;&nbsp;{{ config[2].skill[0].count }}次</li>
				<li>技能二：{{ skill[config[2].skill[1].s] }}&nbsp;&nbsp;{{ config[2].skill[1].count }}次</li>
				<li>技能三：{{ skill[config[2].skill[2].s] }}&nbsp;&nbsp;{{ config[2].skill[2].count }}次</li>
				<li>技能四：{{ skill[config[2].skill[3].s] }}&nbsp;&nbsp;{{ config[2].skill[3].count }}次</li>
				<li>血量&lt;{{ config[2].hp }}使用{{ yao[0][config[2].hpYao] }}</li>
				<li>蓝量&lt;{{ config[2].mp }}使用{{ yao[1][config[2].mpYao] }}</li>
			</ul>
		</div>
		<div>
			<div>副三：</div>
			<ul>
				<li>技能一：{{ skill[config[3].skill[0].s] }}&nbsp;&nbsp;{{ config[3].skill[0].count }}次</li>
				<li>技能二：{{ skill[config[3].skill[1].s] }}&nbsp;&nbsp;{{ config[3].skill[1].count }}次</li>
				<li>技能三：{{ skill[config[3].skill[2].s] }}&nbsp;&nbsp;{{ config[3].skill[2].count }}次</li>
				<li>技能四：{{ skill[config[3].skill[3].s] }}&nbsp;&nbsp;{{ config[3].skill[3].count }}次</li>
				<li>血量&lt;{{ config[3].hp }}使用{{ yao[0][config[3].hpYao] }}</li>
				<li>蓝量&lt;{{ config[3].mp }}使用{{ yao[1][config[3].mpYao] }}</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: "FightContent",
		props: {
			config: Array
		},
		data() {
			return {
				skill: {
					0: "普通攻击",
					1: "舍命一击",
					2: "招降怪物",
					3: "呼风唤雨",
					5: "画地为牢",
					6: "妙手回春",
					7: "气冲斗牛",
					8: "妖火燎原",
					9: "力劈华山",
					10: "排山倒海",
					11: "趁火打劫",
					12: "四面楚歌",
					13: "五雷轰顶",
					14: "巫蛊极毒",
					15: "固若金汤",
					16: "金蝉脱壳",
					17: "毁天灭地",
					18: "暗度陈仓",
					19: "凌波微步",
				},
				yao: [
					{
						1: "跌打药",
						2: "止血药",
						3: "金创药",
						4: "麻沸散",
						5: "酥合散",
						6: "断续膏",
						7: "三黄丸",
						8: "百花丸",
						9: "熊胆丸",
						10: "首乌丸",
						11: "雪参丸",
						12: "小还丹",
						13: "大还丹",
						14: "护命丹",
						15: "回天丹",
						16: "九转丹",
					},
					{
						17: "回春汤",
						18: "花蜜酒",
						19: "清泉酒",
						20: "菖蒲酒",
						21: "苦艾酒",
						22: "花雕酒",
						23: "杜康酒",
						24: "虎骨酒",
						25: "雄黄酒",
						26: "强生酒",
						27: "万古酒",
						28: "芸香露",
						29: "红莲露",
						30: "镇魂露",
						31: "玄冰露",
						32: "龙涎露",
					}
				]
			}
		}
	}
</script>

<style scoped>
	.main {
		margin: 0 1rem;
		font-size: .8rem;
	}
	ul {
		margin-left: 1rem;
	}
</style>
