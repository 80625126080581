<template>
	<header>
		<h1>{{ title }}</h1>
	</header>
</template>

<script>
	export default {
		name: "Header",
		props: {
			title: String
		}
	}
</script>

<style scoped>
	header {
		height: 2.6rem;
		background-color: #f5f5f5;
	}
	h1 {
		font-size: 1.2rem;
		text-align: center;
		line-height: 2.6rem;
	}
</style>
