<template>
	<main>
		<slot></slot>
	</main>
</template>

<script>
	export default {
		name: "Main"
	}
</script>

<style scoped>
	main {
		position: fixed;
		top: 2.6rem;
		bottom: 2.7rem;
		overflow-y: auto;
		width: 20rem;
	}
</style>
