<template>
	<li>
		<span>{{ name }}</span>
		<slot></slot>
	</li>
</template>

<script>
	export default {
		name: "FightList",
		props: {
			name: String
		}
	}
</script>

<style scoped>
	li {
		position: relative;
		height: 1.5rem;
		margin-top: 1rem;
		padding: 0 .5rem;
		background-color: #eee;
		border-radius: .75rem;
		text-indent: 1rem;
	}
	button {
		position: absolute;
		right: 1rem;
		margin: .1rem 0;
		height: 1.3rem;
		width: 3rem;
		background-color: #fff;
		border-radius: .75rem;
	}
</style>
