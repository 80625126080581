<template>
	<div class="ingo">
		<div>
			<div class="header">{{ name }}<slot name="img"></slot></div>
			<div class="main">
				<slot name="info"></slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "FightInfo",
		props: {
			name: String
		},
		methods: {

		}
	}
</script>

<style scoped lang="less">
	.ingo {
		position: absolute;
		top: 0;
		padding: 3rem;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .4);
		>div {
			position: relative;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			border-radius: 1rem;
			>.header {
				position: relative;
				text-align: center;
				line-height: 1.5rem;
				font-weight: 700;
				height: 1.5rem;
				background-color: #f4f4f4;
				>img {
					position: absolute;
					right: .5rem;
					height: 1.5rem;
					padding: .2rem;
				}
			}
			>.main {
				position: absolute;
				top: 1.5rem;
				bottom: 0;
				left: 0;
				right: 0;
				overflow-y: auto;
				background-color: #fff;
			}
		}
	}
</style>
