<template>
	<div class="main">
		<div class="name"><span>配置名称：</span><input v-model="name" type="text"></div>
		<div v-for="(k, v) in jiang" :key="v" class="content">
			<p>{{ k }}：</p>
			<ul>
				<li v-for="(i, j) in up" :key="j">
					<span>技能{{ i }}：</span>
					<select v-model="mitData[v].skill[j].s">
						<option v-for="(i, j) in skill" :key="j" :value="j">{{ i }}</option>
					</select>
					<span>用</span>
					<input v-model="mitData[v].skill[j].count" type="number" >
					<span>次</span>
				</li>
				<li v-for="(c, d) in lp" :key="d">
					<span>{{ c }}量&lt;</span>
					<input v-model="mitData[v][p[d]]" style="width: 3rem" type="number">
					<span>用</span>
					<select v-model="mitData[v][p[d]+'Yao']">
						<option v-for="(a, b) in yao[d]" :key="b" :value="b">{{ a }}</option>
					</select>
				</li>
			</ul>
		</div>
		<button @click="submit">保存</button>
	</div>
</template>

<script>
	import {addFightInfo} from "@/network/api";

	export default {
		name: "AddFight",
		methods: {
			submit() {
				if (this.name == "") {
					alert("战斗方式名称不能为空")
				} else {
					addFightInfo(this.$store.state.token, this.name, JSON.stringify(this.mitData)).then(
						res => {
							if (res.code == 200){
								alert("添加成功")
								this.$router.replace("/home")
							}
						}
					)
				}
			}
		},
		data() {
			return {
				name: "",
				mitData: [
					{
						mp: 1000,
						mpYao: 17,
						hp: 2000,
						hpYao: 1,
						skill: [
							{s: 0, count: 5},
							{s: 0, count: 5},
							{s: 0, count: 5},
							{s: 0, count: 5},
						]
					},
					{
						mp: 1000,
						mpYao: 17,
						hp: 2000,
						hpYao: 1,
						skill: [
							{s: 0, count: 5},
							{s: 0, count: 5},
							{s: 0, count: 5},
							{s: 0, count: 5},
						]
					},
					{
						mp: 1000,
						mpYao: 17,
						hp: 2000,
						hpYao: 1,
						skill: [
							{s: 0, count: 5},
							{s: 0, count: 5},
							{s: 0, count: 5},
							{s: 0, count: 5},
						]
					},
					{
						mp: 1000,
						mpYao: 17,
						hp: 2000,
						hpYao: 1,
						skill: [
							{s: 0, count: 5},
							{s: 0, count: 5},
							{s: 0, count: 5},
							{s: 0, count: 5},
						]
					}
				],
				up: ["一", "二", "三", "四"],
				lp: ["血", "蓝"],
				jiang: ["主将", "副一", "副二", "副三"],
				p: ["hp", "mp"],
				yao: [
					{
						1: "跌打药",
						2: "止血药",
						3: "金创药",
						4: "麻沸散",
						5: "酥合散",
						6: "断续膏",
						7: "三黄丸",
						8: "百花丸",
						9: "熊胆丸",
						10: "首乌丸",
						11: "雪参丸",
						12: "小还丹",
						13: "大还丹",
						14: "护命丹",
						15: "回天丹",
						16: "九转丹",
					},
					{
						17: "回春汤",
						18: "花蜜酒",
						19: "清泉酒",
						20: "菖蒲酒",
						21: "苦艾酒",
						22: "花雕酒",
						23: "杜康酒",
						24: "虎骨酒",
						25: "雄黄酒",
						26: "强生酒",
						27: "万古酒",
						28: "芸香露",
						29: "红莲露",
						30: "镇魂露",
						31: "玄冰露",
						32: "龙涎露",
					}
				],
				skill: {
					0: "普通攻击",
					1: "舍命一击",
					2: "招降怪物",
					3: "呼风唤雨",
					5: "画地为牢",
					6: "妙手回春",
					7: "气冲斗牛",
					8: "妖火燎原",
					9: "力劈华山",
					10: "排山倒海",
					11: "趁火打劫",
					12: "四面楚歌",
					13: "五雷轰顶",
					14: "巫蛊极毒",
					15: "固若金汤",
					16: "金蝉脱壳",
					17: "毁天灭地",
					18: "暗度陈仓",
					19: "凌波微步",
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.main {
		font-size: .8rem;
		margin: 0 1rem;
		>.name {
			input {
				border-bottom: 1px solid #666;
				width: 50%;
				font-size: .8rem;
			}
		}
		>.content {
			p {
				font-weight: 700;
			}
			ul {
				margin-left: 1rem;
				input {
					width: 1.5rem;
					font-size: .8rem;
					border-bottom: 1px solid #666;
				}
			}
		}
	}
	button {
		margin: .5rem 0;
		width: 100%;
		height: 1.5rem;
		background-color: #666;
		border-radius: .75rem;
		font-size: .8rem;
		color: #fff;
	}
</style>
